<template>
     <video id="upvideo" style="width: 200px;height: 200px"
            :src="videoUrl" class="avatar" controls="controls">您的浏览器不支持视频播放
     </video>
     <canvas id="mycanvas" style="display: none"></canvas>
     <img :src="imgUrl">
</template>

<script>
  export default {
    name: "test",
    data() {
      return {
        videoUrl: "/obs/media/1619013832550824.mp4",
        imgUrl: ""
      }
    },
    mounted() {
      this.findvideocover();
    },
    methods: {
      findvideocover() {
        const video = document.getElementById("upvideo"); // 获取视频对象
        // const video = document.createElement("video") // 也可以自己创建video
        video.src = this.videoUrl; // url地址 url跟 视频流是一样的
        var canvas = document.getElementById('mycanvas') // 获取 canvas 对象
        const ctx = canvas.getContext('2d'); // 绘制2d
        video.crossOrigin = 'Anonymous' // 解决跨域问题，也就是提示污染资源无法转换视频
        video.currentTime = 1 // 第一帧
        video.oncanplay = () => {
          canvas.width = video.clientWidth; // 获取视频宽度
          canvas.height = video.clientHeight; //获取视频高度
          // 利用canvas对象方法绘图
          ctx.drawImage(video, 0, 0, video.clientWidth, video.clientHeight)
          // 转换成base64形式
          this.imgUrl = canvas.toDataURL("image/png") // 截取后的视频封面
          //file.url=this.imgsrc;
        }
      },
    }
  }
</script>

<style scoped>

</style>